import { getClientRect } from "dom-plane";
import _ from "lodash-es";
import { MBUI, kWin, kDoc, isEmpty } from "./constants";

let myScrollbarWidth;

export const stylePrefix = _.memoize((property) => {
  const capitalizedProp = property.charAt(0).toUpperCase() + property.slice(1);
  const prefixes = ["", "webkit", "moz", "ms", "o"];
  const i = _.findIndex(
    prefixes,
    (prefix) => typeof kDoc.body.style[prefix ? prefix + capitalizedProp : property] !== _.noop()
  );
  return i === -1 ? _.noop() : i === 0 ? property : prefixes[i] + capitalizedProp;
});

export const setFastElementPosition = (theElement, theLeft = 0, theTop = 0) => {
  if (!isEmpty(theElement) && theElement.isConnected) {
    theElement.style[stylePrefix("transform")] = `translate3d(${_.round(theLeft)}px, ${_.round(theTop)}px, 0)`;
    theElement.style.left = 0;
    theElement.style.top = 0;
    theElement.style.zIndex = 9999;
  }
};

export const getScrollbarWidth = _.memoize(() => {
  if (!!myScrollbarWidth) {
    return myScrollbarWidth;
  }
  const docBody = kDoc.body;
  let scrollDiv = kDoc.createElement("div");
  scrollDiv.className = "modal-scrollbar-measure";
  docBody.appendChild(scrollDiv);
  const { width } = getClientRect(scrollDiv);
  myScrollbarWidth = width - scrollDiv.clientWidth;
  docBody.removeChild(scrollDiv);
  return myScrollbarWidth;
});

export const getViewportScrollPosition = () => {
  // Use the document element's bounding rect rather than the window scroll properties
  // (e.g. pageYOffset, scrollY) due to in issue in Chrome and IE where window scroll
  // properties and client coordinates (boundingClientRect, clientX/Y, etc.) are in different
  // conceptual viewports. Under most circumstances these viewports are equivalent, but they
  // can disagree when the page is pinch-zoomed (on devices that support touch).
  // See https://bugs.chromium.org/p/chromium/issues/detail?id=489206#c4
  // We use the documentElement instead of the body because, by default (without a css reset)
  // browsers typically give the document body an 8px margin, which is not included in
  // getBoundingClientRect().
  const { top, left } = getViewportRect();
  const { width, height } = getViewportSize();

  return {
    top,
    left,
    bottom: top + height,
    right: left + width,
    height,
    width,
  };
};

export const getViewportSize = () => ({ width: kWin.innerWidth, height: kWin.innerHeight });

export const getViewportRect = () => {
  // The top-left-corner of the viewport is determined by the scroll position of the document
  // body, normally just (scrollLeft, scrollTop). However, Chrome and Firefox disagree about
  // whether `document.body` or `document.documentElement` is the scrolled element, so reading
  // `scrollTop` and `scrollLeft` is inconsistent. However, using the bounding rect of
  // `document.documentElement` works consistently, where the `top` and `left` values will
  // equal negative the scroll position.
  const documentElement = document.documentElement;
  const documentRect = documentElement.getBoundingClientRect();
  const top = -documentRect.top || document.body.scrollTop || kWin.scrollY || documentElement.scrollTop || 0;
  const left = -documentRect.left || document.body.scrollLeft || kWin.scrollX || documentElement.scrollLeft || 0;

  return { top, left };
};

export const hasWinSelection = () =>
  kWin.getSelection ? kWin.getSelection() : document.selection ? document.selection : _.noop();

export const clearSelection = () => {
  if (!!hasWinSelection()) {
    hasWinSelection().empty ? hasWinSelection().empty() : hasWinSelection().removeAllRanges();
  }
};

export const scheduler = (fn, ms, ...args) => {
  const id = setTimeout(() => {
    fn(...args);
    clearTimeout(id);
  }, ms);
};

export const setImmediateTask = (fn, ...args) => {
  const id = window.setImmediate(() => {
    fn(...args);
    clearImmediate(id);
  });
};

export const uniqueTokenList = (...arr) => new Set(_.compact(_.split(_.trim(_.join(arr, " ")), " ")));

const toggleAddRemove = (theAdd, arr1, arr2) => (theAdd ? _.difference(arr1, arr2) : _.intersection(arr1, arr2));

export const uniqueClassNames = (...theStrings) => Array.from(uniqueTokenList(...theStrings)).join(" ");

export const conditionalClassList = (theElement, theAdd, ...theClasses) => {
  if (_.isElement(theElement) && theElement.classList) {
    theElement.classList[theAdd ? "add" : "remove"](
      ...toggleAddRemove(theAdd, Array.from(uniqueTokenList(...theClasses)), theElement.classList)
    );
  }
};

const timeTaken = (callback) => {
  console.time("timeTaken");
  const r = callback();
  console.timeEnd("timeTaken");
  return r;
};

//const osHasReducedMotion = () => {
//	if (!window.matchMedia) return false;
//	const matchMediaObj = window.matchMedia('(prefers-reduced-motion: reduce)');
//	if (matchMediaObj) return matchMediaObj.matches;
//	return false; // return false if not supported
//};

Object.assign(MBUI.util, {
  clearSelection,
  getScrollbarWidth,
  getViewportScrollPosition,
  scheduler,
  setFastElementPosition,
  setImmediateTask,
  stylePrefix,
  timeTaken,
  conditionalClassList,
  uniqueClassNames,
  uniqueTokenList,
});
